import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Box,
  Button,
  FormLabel,
  Input,
  Checkbox,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card";
import { useAuth } from "context/AuthContext";
import { useEffect } from "react";
import { MsgContainerTopCenter } from "utils/authError";
import { DeleteIcon } from "@chakra-ui/icons";
import { FaSort } from "react-icons/fa";
import {
  planType,
  spacesType,
  environmentType,
} from "types/userTypes";
import { uniqBy } from "lodash";
import { Prompt } from "./environment/prompt";
import { Link } from "react-router-dom";
import { dispatchPopup } from "components/popups";
import { processQueryparams } from "components/popups/envs/utils";
import { Prompt as NavBlock } from "react-router-dom";
import { useSpaces } from "./usespaces";

const headData = [
  { id: 1, title: "Name" },
  { id: 2, title: "Plans" },
  { id: 3, title: "Environments" },
  { id: 4, title: "Members" },
  { id: 5, title: "Archive" },
];

export default function Spaces({
  saveClicked,
  btnId,
}: {
  saveClicked: boolean;
  btnId: number;
}) {
  const {
    spaces,
    permission,
  } = useAuth();

  ///////////////////////////////////////////////////////////////////////////////
  // Register popups for use

  /**
   * Add plan popup
   * @param spaceId space id 
   * @returns a function to dispatch the popup
   */
  const addPlan = (spaceId: string) => dispatchPopup('AddPlan', { spaceId })();

  /**
   * Add environment popup
   * @param spaceId space id
   * @returns a function to dispatch the popup
   */
  const addEnv = (spaceId: string) => dispatchPopup('AddEnv', { spaceId })();

  /**
   * Create a new space popup
   * @returns a function to dispatch the popup
   */
  const createSpace = () => dispatchPopup('CreateSpace', {})();

  /**
   * Edit environment popup
   * @param props spaceId, and envId
   * @returns a function to dispatch the popup
   */
  const editEnv = ({ spaceId, envId }: { spaceId: string, envId: string }) =>
    dispatchPopup('EditEnv', { spaceId, envId })();

  const editPlan = ({ spaceId, planId }: { spaceId: string, planId: string }) =>
    dispatchPopup('EditPlan', { spaceId, planId })();

  ///////////////////////////////////////////////////////////////////////////////

  // useSpace hook

  const {
    selectedSpace,
    selectedSpacePlans,
    selectedSpaceEnvs,
    disableSave,
    updateName,
    del,
    selectSpace,
    setArchived,
    sort,
  } = useSpaces(btnId === 1 && saveClicked);

  // Handle actions

  const action = processQueryparams()['action'];

  const handleActions = () => {
    switch (action) {
      case "launch_plan":
        addPlan(selectedSpace.id);
        break;
      default:
        break;
    }
  }

  // eslint-disable-next-line
  useEffect(handleActions, []);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgHighlight = useColorModeValue("blue.500", "blue.300");
  const textHighlight = useColorModeValue("white", "gray.900");
  const disabledColor = useColorModeValue("gray.400", "gray.600");

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <NavBlock
        message={"You have unsaved changes are you sure you want to leave?"}
        when={!disableSave}
      />
      {MsgContainerTopCenter}
      <Flex px="25px" justify="flex-end" mb="20px" align="center">
        <Button
          variant="outline"
          colorScheme="blue"
          fontWeight="500"
          fontSize="15px"
          onClick={createSpace}
          disabled={permission === "viewer"}
        >
          Create new space
        </Button>
      </Flex>

      <Table variant="simple" color="gray.500" mb="24px">
        <Thead>
          <Tr>
            {headData.map(({ id, title }) => (
              <Th key={id} borderColor={borderColor}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap="8px"
                >
                  {id === 1 && <FaSort cursor="pointer" onClick={sort.handleSort} />}

                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {title}
                  </Flex>
                </Box>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody color="black">
          {spaces.length > 0 &&
            spaces
              .sort((a: any, b: any) => sort.sortSpaces(a, b))
              .map((space: spacesType, index: number) => (
                <Tr
                  fontSize="14px"
                  key={index}
                  color={
                    space.status === "archived" ?
                      disabledColor : textColor
                  }
                  textTransform="capitalize"
                  id={space.id}
                  bg={
                    selectedSpace &&
                    selectedSpace.id === space.id &&
                    borderColor
                  }
                  onClick={() => {
                    console.log("space debug", space)
                    selectSpace(space)
                  }}
                  _hover={{ cursor: "pointer" }}
                >
                  <Td>{space.name}</Td>
                  <Td> {uniqBy(space.plans, "id").length} </Td>
                  <Td>{space.environments.length}</Td>
                  <Td>{space.members.length}</Td>
                  <Td>
                    <Checkbox colorScheme="blue" borderColor="gray.300"
                      name={space.id} onChange={setArchived}
                      value={
                        space.status === "archived" ?
                          "active" : "archived"
                      }
                      isChecked={space.status === "archived"}
                    />
                  </Td>
                </Tr>
              ))}
        </Tbody>
      </Table>

      {selectedSpace && selectedSpace.id && (
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          mt="32px"
        >
          <Box>
            <Text align="center" fontWeight="bold" fontSize="20px">
              Selected Space
            </Text>
            <Flex
              direction="row"
              justifyContent="center"
              alignItems="center"
              background="transparent"
              borderRadius="15px"
            >
              <Box
                w={{ base: "100%", md: "420px" }}
                maxW="100%"
                ml="16px"
                mr="16px"
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Name
                </FormLabel>
                <Input
                  placeholder="name"
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  type="text"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  name={selectedSpace && selectedSpace.id}
                  value={selectedSpace.name}
                  onChange={updateName}
                />
              </Box>
            </Flex>
            <Flex
              direction={{ base: "row", sm: "column", md: "row" }}
              justifyContent="space-between"
              alignItems="flex-start"
              borderRadius="15px"
              gap={{ base: "10rem", sm: "1rem", md: "10rem" }}
              mt="1rem"
            >
              <Box
                w={{ base: "100%", md: "auto" }}
                maxW="100%"
                ml="16px"
                mr="16px"
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Plans
                </FormLabel>
                {uniqBy(selectedSpacePlans, "id").map(
                  (plan: planType, index: number) => (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      gap=".5rem"
                      key={index}
                      mt="8px"
                      ml="6px"
                    >
                      {permission !== "viewer" && (
                        <Prompt
                          prompt={plan.name}
                          action={() => del.onDeletePlan(plan.id)}
                        >
                          <DeleteIcon cursor='pointer' />
                        </Prompt>
                      )}
                      {/* <Link to={`/dashboard/cloud-design/${selectedSpace.id}/${plan.id}/1`} > */}
                      <Text
                        textTransform="capitalize"
                        cursor="pointer"
                        p="0 .5rem"
                        onClick={() =>
                          editPlan({ spaceId: selectedSpace.id, planId: plan.id })
                        }
                        _hover={{
                          bg: bgHighlight,
                          color: textHighlight,
                          borderRadius: "8px",
                        }}
                      >{plan.name}</Text>
                      {/* </Link> */}
                    </Box>
                  )
                )}
                <Button
                  colorScheme="blue"
                  mt="20px"
                  mb="20px"
                  onClick={() => addPlan(selectedSpace.id)}
                  disabled={permission === "viewer"}
                >
                  Add New Plan
                </Button>
              </Box>
              {/*  TODO #44 */}
              <Box
                w={{ base: "100%", md: "auto" }}
                maxW="100%"
                ml="16px"
                mr="16px"
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Environments
                </FormLabel>
                {selectedSpaceEnvs.length > 0 &&
                  uniqBy(selectedSpaceEnvs, "id").map((env: environmentType, index: number) => (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      gap=".5rem"
                      key={index}
                      mt="8px"
                      ml="6px"
                    >
                      {permission !== "viewer" && (
                        <Prompt
                          prompt={env.name}
                          action={() => del.onDeleteEnv(env.id)}
                        >
                          <DeleteIcon cursor='pointer' />
                        </Prompt>
                      )}
                      {/* <Checkbox onChange={(e) => onSelectEnv(e, env)} /> */}
                      <Text
                        textTransform="capitalize"
                        cursor='pointer'
                        onClick={() => {
                          const envId = env.id;
                          const spaceId = selectedSpace.id;
                          editEnv({ spaceId, envId })
                        }}
                        p="0 .5rem"
                        _hover={{
                          bg: bgHighlight,
                          color: textHighlight,
                          borderRadius: "8px",
                        }}
                      >{env.name}</Text>
                    </Box>
                  ))}
                <Button
                  colorScheme="blue"
                  mt="20px"
                  mb="20px"
                  onClick={() => {
                    addEnv(selectedSpace.id);
                  }}
                  disabled={permission === "viewer"}
                >
                  Add New Environment
                </Button>
              </Box>
            </Flex>
          </Box>
        </Flex>
      )}
    </Card>
  );
};
