import React, { FC, Fragment, useEffect, useState } from 'react';
import { ModalBody, Text } from '@chakra-ui/react';
import { usePlans } from './useplans';
import { CustomInput } from '../components';
import { CustomModalFooter } from '../components';
import { pkgs } from 'functions';
import { editPlanProps } from '../types';
import { Package } from 'functions/types';

/**
 * Edit plan popup - allows users to edit the name of a plan
 * @param props edit plan props
 * @returns JSX.Element
 */
export const EditPlan: FC<editPlanProps> = props => {
  const { planId, spaceId, onClose } = props;
  const [oldName, setOldName] = useState("");
  const [diff, setDiff] = useState(false);
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState({
    name: "",
    package: "",
  });
  const [packageDetails, setPackageDetails] = useState<Package | {}>({
    name: "",
    library: "",
    runtime: "",
    vendor: "",
    language: "",
  });
  const [disabled, setDisabled] = useState(true);
  const { listPlans, editPlan } = usePlans(spaceId);
  const [nameError, setNameError] = useState(false);


  /**
   * Set plan name and package when the planId changes and when the plans 
   * collection changes
   */
  useEffect(() => {
    if (!listPlans) return;
    const plan = listPlans.find(plan => plan.id === planId);
    if (plan) {
      setPlan({
        name: plan.name,
        package: pkgs.getPackageName(plan.package) || "This plan has no package",
      });
      const __packageDetails = pkgs.packages.find(pkg => pkg.id === plan.package);
      const data: any = __packageDetails?.package || {};
      setPackageDetails({
        name: data.name || " ",
        library: data.library || " ",
        runtime: data.runtime || " ",
        vendor: data.vendor || " ",
        language: data.language || " ",
        deleted: data.deleted || false,
        deletedAt: data.deletedAt || " ",
      });
      setOldName(plan.name);
      setDiff(false);
    }
  }, [listPlans, planId]);

  /**
   * Handle saving new plan metadata onchanges
   */
  const handleEditPlan = async () => {
    setLoading(true);
    await editPlan(planId, plan.name);
    setLoading(false);
    onClose();
  }

  /**
   * Onchange event handler for plan name input
   * @param e input event
   */
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setNameError(validateName(name));
    setPlan({ ...plan, name });
    if (name === oldName) {
      setDiff(false);
    } else {
      setDiff(true);
    }
  }

  /**
   * Validate plan name
   * @param name Plan name
   * @returns boolean
   * @description Plan name must be at least 3 characters
   */
  const validateName = (name: string) => {
    const __name = name.trim();

    if (__name.length < 3) {
      setDisabled(true);
      return true;
    }
    setDisabled(false);
    return false;
  }


  return (
    <Fragment>
      <ModalBody>
        <CustomInput
          label="Plan name"
          placeholder="Enter plan name"
          value={plan.name}
          onChange={onChange}
          error={"Plan name must be at least 3 characters"}
          isInvalid={nameError}
          onBlur={e => validateName(e.target.value)}
        />
        <Text fontSize="md" fontWeight="500" mb="8px"
          textAlign="center"
        >Package details (readonly)</Text>
        {
          plan.package && packageDetails &&
          Object.entries(packageDetails).map(([key, value]) =>
            <CustomInput
              key={key}
              label={`Package ${key}`}
              placeholder={`Enter Package ${key}`}
              value={value}
              error={`Package ${key} must be at least 3 characters}`}
              isInvalid={false}
              disabled
            />
          )
        }
      </ModalBody>
      <CustomModalFooter
        disabled={disabled || !diff || loading || nameError}
        cancelLabel="Cancel"
        onAccept={handleEditPlan}
        onCancel={onClose}
        acceptLabel={loading ? "Updating..." : "Save"}
      />
    </Fragment>
  );
}
