import { useAuth } from "context/AuthContext"
import { addDoc, collection, onSnapshot, query, updateDoc, where, doc } from "firebase/firestore";
import { col } from "functions/types";
import { db } from "firebase-local/config"
import { useRef, useState, useEffect } from "react";
import { AutoSave } from "components/navbar/savechanges";
import { pkgs } from "functions";

export const usePlans = (spaceID: string = null) => {
  const { docId, getOrgsData, getPlanId, spaces } = useAuth();
  const [plans, setPlans] = useState<any[]>([]);
  const [listPlans, setListPlans] = useState<any[]>([]);
  const spaceRef = useRef(null);

  useEffect(() => {
    const spaceId = spaceRef.current;
    if (!spaceId) return;

    const planRef = collection(
      db, col.orgs, docId, col.teams, docId, col.spaces,
      spaceId, col.plans);

    const planQuery = query(planRef, where('content', '!=', ""));

    const unsubscribe = onSnapshot(planQuery, (snapshot) => {
      const plans = snapshot.docs.map(doc => ({
        name: doc.data().name,
        content: doc.data().content || null,
        id: doc.id
      }));
      setPlans(plans);
    });

    return () => unsubscribe();

  }, [docId]);

  /**
   * Sets the list of plans to be displayed in the popup
   */
  useEffect(() => {
    const spaceId =  spaceID;
    if (!spaceId) return;

    const plans = spaces.find(space => space.id === spaceId)?.plans;
    setListPlans(plans || []);
  }, [spaceID, spaces]);


  /**
   * Add new plan to the database
   * @param spaceId space id
   * @param data plan metadata
   */
  const addPlan = async (spaceId: string, data: any) => {
    try {
      const planRef = collection(
        db, col.orgs, docId, col.teams, docId, col.spaces,
        spaceId, col.plans);

      const packageCfg = await pkgs.packageCfg();

      const res = await addDoc(planRef, {
        ...data,
        ...packageCfg
      });
      getOrgsData();
      getPlanId(res.id);
      AutoSave.display('Plan added successfully')
    } catch (error) {
      console.log('addPlan(): ', error);
      AutoSave.error('Error adding plan')
    }
  }

  /**
   * editPlan - Saves the new plan name to the database
   * @param planId plan id
   * @param name plan name
   * @param spaceId space id (optional)
   * @returns void
   */
  const editPlan = async (planId: string, name: string, spaceId = spaceID) => {
    try {

      if (!name) return AutoSave.error('Plan name cannot be empty');
      const planRef = collection(
        db, col.orgs, docId, col.teams, docId, col.spaces,
        spaceId, col.plans);

      await updateDoc(doc(planRef, planId), { name });
      getOrgsData();
      AutoSave.display('Plan updated successfully')
    } catch (error) {
      console.log('editPlan(): ', error);
      AutoSave.error('Error updating plan')
    }
  }

  return { addPlan, editPlan, spaceRef, plans, listPlans }
}
