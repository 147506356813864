import { CreateSpace } from "./spaces";
import { EditEnv, AddEnv } from "./envs";
import { AddPlan, AddCode } from "./plans";
import { InviteMember } from "./teams";
import { EditPlan } from "./plans";

/**
 * Get a popup component from a list of popups
 * @param key Key of the popup
 * @returns An object containing the popup
 * component and the title or null if the key
 * is not found
 */
export const getPopup = (key: string) => {
  switch (key) {
    // Create Space
    case "CreateSpace":
      return {
        element: () => CreateSpace,
        title: "Create new space"
      }

    // Add Plan
    case "AddPlan":
      return {
        element: () => AddPlan,
        title: "Add new plan"
      }

    // Add code
    case "AddCode":
      return {
        element: () => AddCode,
        title: "Custom code block"
      }

    // Add Environment
    case "AddEnv":
      return {
        element: () => AddEnv,
        title: "Add new environment"
      }

    // Edit Environment
    case "EditEnv":
      return {
        element: () => EditEnv,
        title: "Edit environment"
      }

    // Invite Team Member
    case "InviteMember":
      return {
        element: () => InviteMember,
        title: "Invite new member"
      }

    // Edit Plan
    case "EditPlan":
      return {
        element: () => EditPlan,
        title: "Edit plan"
      }

    default:
      return null;
  }
};
