import React, { createContext, useContext, useEffect, useState } from "react";
import { db, auth } from "firebase-local/config";
import { apiUrl } from "firebase-local/urlPath";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  where,
  collectionGroup,
} from "firebase/firestore";
import {
  userTypes,
  spacesType,
  memberType,
  billingType,
  UserAssociatedOrgs,
  UserAssociatedTeams,
} from "../types/userTypes";

import {
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { DateTime } from "functions";

import { pkgs } from "functions";
import { col } from "functions/types";

// Separate billing from user
const userInitialData = {
  name: "",
  email: "",
};

// const initialTeamSettings = {
//   timeZone: "",
//   entries: 1
// }

const initialBilling = {
  plan: "",
  renewal: "",
  cycle: "",
  email: "",
};

// Orgs associated to the current user
const userAssociatedOrgs: UserAssociatedOrgs = {};

// Teams associated to the current user
const userAssociatedTeams: UserAssociatedTeams = [];

const AuthContext = createContext({
  currentUser: { auth, uid: "" },
  register: async (email: string, password: string, name: string): Promise<any> => Promise,
  login: (email: string, password: string): any => Promise,
  logout: () => Promise,
  forgotPassword: (email: string): any => Promise,
  resetPassword: () => Promise,
  getLayoutZindex: (zIndex: boolean) => { },
  getUserData: (email: string) => { },
  getOrgsData: () => { },
  getMembersData: () => { },
  getTotalPages: () => { },
  onPrevPageClick: () => { },
  onNextPageClick: () => { },
  getSpaceId: (spaceId: string) => { },
  getPlanId: (spaceId: string) => { },
  getTabId: (spaceId: number) => { },
  updateDocID: (id: string) => { },
  updateTeamID: (id: string) => { },
  layoutZindex: false,
  userEmail: "",
  userId: "",
  isLoading: true,
  userData: userInitialData,
  billingData: initialBilling,
  docId: "",
  teamId: "",
  spaces: [],
  members: [],
  teamName: "",
  starting: 0,
  ending: 10,
  currentPage: 1,
  totalPages: 1,
  spaceId: "",
  planId: "",
  billingTeamTab: 1,
  cloudSettingsTab: 1,
  cloudReleaseTab: 1,
  cloudDesignTab: 1,
  permission: "",
  userAssociatedTeams,
  userAssociatedOrgs,
  orgIds: [],
  getBillingTeamTab: (tabId: number) => { },
  getCloudSettingsTab: (tabId: number) => { },
  getCloudReleaseTab: (tabId: number) => { },
  getCloudDesignTab: (tabId: number) => { },
  getCurrentUserTeams: () => { },
  getSpaceStatus: (id: string, status: "archived" | "active") => { },
});

export const useAuth = () => useContext(AuthContext);

type Props = {
  children: JSX.Element;
};

const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [layoutZindex, setLayoutZindex] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userData, setUserData] = useState<userTypes>(userInitialData);
  const [docId, setDocId] = useState<string>("");
  const [billingData, setBillingData] = useState<billingType>(initialBilling);

  // ORGS DATA
  const [spaces, setSpaces] = useState<spacesType[]>([]);
  const [members, setMembers] = useState<memberType[]>([]);
  const [teamName, setTeamName] = useState<string>("");
  const [userAssociatedOrgs, setUserAssociatedOrgs] = useState<UserAssociatedOrgs>({});

  // Pagination

  const [starting, setStarting] = useState<number>(0);
  const [ending, setEnding] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [entries, setEntries] = useState<number>(10);

  // Dropdown
  const [spaceId, setSpaceId] = useState<string>("");
  const [planId, setPlanId] = useState<string>("");

  // Tabs
  const [billingTeamTab, setBillingTeamTab] = useState<number>(1);
  const [cloudSettingsTab, setCloudSettingsTab] = useState<number>(1);
  const [cloudReleaseTab, setCloudReleaseTab] = useState<number>(1);
  const [cloudDesignTab, setCloudDesignTab] = useState<number>(1);

  // Permission
  const [permission, setPermission] = useState<string>("");

  // Teams
  const [userAssociatedTeams, setUserAssociatedTeams]
    = useState<UserAssociatedTeams>([]);
  const [teamId, setTeamId] = useState<string>("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user: any) => {
      setCurrentUser(user ? user : null);
      setUserEmail(user ? user.auth.currentUser.email : "");
      setUserId(user ? user.uid : "");
      setIsLoading(false);
      getUserData(user && user.auth.currentUser.email);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    getUserPermission();
    getCurrentUserTeams();

    if (docId) {
      getOrgsData();
      getMembersData();
      checkName();
      pkgs.getPackages()
    }
    // eslint-disable-next-line
  }, [docId]);

  const getUserPermission = () => {
    const membersRef = collection(db, "orgs", docId, "teams", docId, "members");
    onSnapshot(membersRef, (snapshot) => {
      snapshot.docs.forEach((member: any) => {
        if (member.data().email === userEmail) {
          setPermission(member.data().permission);
        }
      });
    });
  };

  // Get tabs
  const getBillingTeamTab = (tabId: number) => {
    setBillingTeamTab(tabId);
  };
  const getCloudSettingsTab = (tabId: number) => {
    setCloudSettingsTab(tabId);
  };
  const getCloudReleaseTab = (tabId: number) => {
    setCloudReleaseTab(tabId);
  };

  const getCloudDesignTab = (tabId: number) => {
    setCloudDesignTab(tabId);
  };

  // Get space and plan Id

  const getSpaceId = (spaceId: string) => {
    setSpaceId(spaceId);
  };
  const getPlanId = (planId: string) => {
    setPlanId(planId);
  };

  const getOrgsData = () => {
    const spacesRef = query(
      collection(db, "orgs", docId, "teams", docId, "spaces"),
      orderBy("createdAt", "desc")
    );

    onSnapshot(spacesRef, (snapshot) => {
      let data: any = [];
      snapshot.docs.forEach((space: any) => {
        const plansRef = collection(
          db,
          "orgs",
          docId,
          "teams",
          docId,
          "spaces",
          space.id,
          "plans"
        );

        const environmentsRef = collection(
          db,
          "orgs",
          docId,
          "teams",
          docId,
          "spaces",
          space.id,
          "environments"
        );
        const spaceMembersRef = collection(
          db,
          "orgs",
          docId,
          "teams",
          docId,
          "spaces",
          space.id,
          "members"
        );
        const billingRef = collection(db, "orgs");

        // Retrieve billing information for the selected org
        onSnapshot(billingRef, snapshot => {
          snapshot.docs.forEach(doc => {
            if (doc.id === docId) {
              const { billing } = doc.data()
              setBillingData(billing)
            }
          });
        });

        let plans: any = [];
        onSnapshot(plansRef, (snapshot) => {
          snapshot.docs.forEach((plan: any) => {
            plans.push({ id: plan.id, name: plan.data().name, package: plan.data().package });
          });
        });
        let environments: any = [];
        onSnapshot(environmentsRef, (snapshot) => {
          snapshot.docs.forEach((env: any) => {
            environments.push({
              id: env.id,
              name: env.data().name,
              vendor: env.data().vendor  // Added vendor 
            });
          });
        });
        let members: any = [];
        onSnapshot(spaceMembersRef, (snapshot) => {
          snapshot.docs.forEach((member: any) => {
            members.push({
              id: member.id,
              email: member.data().email,
              name: member.data().name,
            });
          });
        });

        const obj = {
          id: space.id,
          name: space.data().name,
          status: space.data().status || "active",
          createdAt: space.data().createdAt,
          plans,
          environments,
          members,
        };
        data.push(obj);
      });
      if (snapshot.size === data.length) {
        setTimeout(() => {
          setSpaces(data);
        }, 2000);
      }
    });
  };

  const getMembersData = () => {
    const teamRef = collection(db, "orgs", docId, "teams");
    onSnapshot(teamRef, (snapshot) => {
      snapshot.forEach((doc: any) => {
        setTeamName(doc.data().name);
      });
    });

    const q = query(
      collection(db, "orgs", docId, "teams", docId, "members"),
      orderBy("name", "desc")
    );

    onSnapshot(q, (snapshot) => {
      let memberData: any = [];
      snapshot.docs.forEach((doc: any) => {
        memberData.push({ id: doc.id, ...doc.data() });
      });
      setMembers(memberData);
    });
  };

  // Get all teams the current user within the selected org

  const getCurrentUserTeams = async () => {
    const teamsRef = collection(
      db, col.orgs, docId, col.teams,
    );

    onSnapshot(teamsRef, (snapshot) => {
      setUserAssociatedTeams([]);
      setTeamId("");
      snapshot.docs.forEach(async (doc: any) => {
        const membersRef = collection(
          db, col.orgs, docId, col.teams, doc.id, col.members
        );
        const q = query(membersRef, where("email", "==", userEmail));
        const teams = await getDocs(q);
        if (teams.docs.length) {
          const { name, settings } = doc.data();
          const { id } = doc;
          if (teamId === "") {
            setTeamId(doc.id);
            DateTime.__dateTime.setTimeOffset(settings.timeZone)
          }
          setUserAssociatedTeams(prev => [...prev, { id, name, settings }]);
        }
      });
    });
  };

  /**
   * @description Update the status of a space as active or archived
   * @param id - The id of the space to update
   * @param status - New status of the space
   */
  const getSpaceStatus = (id: string, status: "active" | "archived") => {
    setSpaces((prev) => {
      const newSpaces = [...prev];
      const index = newSpaces.findIndex((space) => space.id === id);
      newSpaces[index].status = status;
      return newSpaces;
    });
  }

  // Pagination
  const getTotalPages = (data: [spacesType]) => {
    const selectedTeam = userAssociatedTeams.find(
      (team) => team.id === teamId
    );
    if (!selectedTeam) return;
    const { settings } = selectedTeam;
    const totalPages = Math.ceil(data.length / settings.entries);
    setTotalPages(totalPages);
    setEntries(settings.entries);
    setEnding(settings.entries);
    setStarting(0);
    setCurrentPage(1);
  };

  const onPrevPageClick = () => {
    if (currentPage === 1) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
      setEnding(ending - entries);
      setStarting(starting - entries);
    }
  };

  const onNextPageClick = () => {
    if (currentPage === totalPages) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
      setEnding(ending + entries);
      setStarting(starting + entries);
    }
  };

  const getUserData = (email: string) => {
    const usersRef = collection(db, "users");
    onSnapshot(usersRef, (snapshot) => {
      snapshot.docs.forEach((doc: any) => {
        if (doc.data().email === email) {
          setUserData(doc.data());
          setDocId(doc.id);
        }
      });
    });
  };

  /**
   * Updates document ID (docId) to point to the current org data
   * @param id Document id
   * @returns void
   */
  const updateDocID = (id: string) => setDocId(id);

  /**
   * Update team ID to the currently selected team
   * @param id Document id (team)
   * @returns void
   */
  const updateTeamID = (id: string) => setTeamId(id);


  const [orgIds, setOrgIds] = useState<string[]>([]);

  /**
   * Update org names and ids for the orgs associated with the current
   * logged in user
   */
  useEffect(() => {
    const members = collectionGroup(db, col.members);
    const q = query(members,
      where("email", "==", userEmail),
      where("reference", "!=", "")
    );
    setOrgIds([]);
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docs.forEach(async (_doc: any) => {
        const orgId = _doc.data().reference;
        setOrgIds(orgIds => [...orgIds, orgId]);
      });
    });

    return () => unsubscribe();
  }, [userEmail]);

  /**
   * Update orgs associated with the current user
   */
  useEffect(() => {
    const orgsRef = collection(db, col.orgs);
    if(!orgIds.length) return;
    const q = query(orgsRef, where("__name__", "in", orgIds));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docs.forEach((doc: any) => {
        setUserAssociatedOrgs(orgs => {
        orgs[doc.id] = { id: doc.id, name: doc.data().name };
        return orgs
        });
      });
    });

    return () => unsubscribe();
  }, [orgIds]);



  async function register(email: string, password: string, name: string) {
    const res = await createUserWithEmailAndPassword(auth, email, password)
      .then(user => {
        if (auth.currentUser) {
          const __name = name === "" ? email.split("@")[0] : name;
          updateProfile(auth.currentUser, { displayName: __name });
        }
        return user;
      })
    return res;
  }

  /**
   * Update user name to the correct value provided during
   * signup process
   * @returns void
   */
  async function checkName() {
    const dispName = auth.currentUser.displayName;

    if (!dispName) return;
    const addressName = userEmail.split("@")[0];
    if (dispName === addressName) return;

    const usersRef = doc(db, "users", docId);
    const userDoc = await getDoc(usersRef);
    const dbDocName = userDoc.data().name;
    if (dbDocName === dispName) return;
    if (dbDocName === addressName) {
      updateDoc(usersRef, { name: dispName });
    }
  }

  function login(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function forgotPassword(email: string) {
    return sendPasswordResetEmail(auth, email, {
      url: apiUrl,
    });
  }

  function logout() {
    return signOut(auth);
  }

  const getLayoutZindex = (zIndex: boolean) => {
    setLayoutZindex(zIndex);
  };

  const value: any = {
    currentUser,
    login,
    register,
    logout,
    forgotPassword,
    getLayoutZindex,
    layoutZindex,
    userEmail,
    userId,
    isLoading,
    userData,
    billingData,
    docId,
    teamId,
    updateTeamID,
    updateDocID,
    getUserData,
    spaces,
    members,
    getMembersData,
    getOrgsData,
    teamName,
    getTotalPages,
    onPrevPageClick,
    onNextPageClick,
    starting,
    ending,
    currentPage,
    totalPages,
    getSpaceId,
    getPlanId,
    spaceId,
    planId,
    billingTeamTab,
    cloudSettingsTab,
    cloudReleaseTab,
    cloudDesignTab,
    permission,
    userAssociatedTeams,
    userAssociatedOrgs,
    orgIds,
    getBillingTeamTab,
    getCloudSettingsTab,
    getCloudReleaseTab,
    getCloudDesignTab,
    getCurrentUserTeams,
    getSpaceStatus,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
