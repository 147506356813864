import { db, storage } from "firebase-local/config";
import { collection, doc, getDocs, getDoc, updateDoc, query, where } from "firebase/firestore";
import { Package, col, ToolingLanguage } from "./types";
import { ref, getBlob } from "firebase/storage";

// Packages list stored in the instance variable packages
interface PackageList { id: string, package: Package, ready?: boolean }

/**
 * Package handler class | Handles various package related operations
 * @param packages list of available packages
 * @param customTemplate custom template file name
 * @param customConfig custom config file name
 * @param cdfManifest cdf manifest file name
 * @param packageId the package id value selected when calling
 * the setPackage method
 */
class Packages {
  packages: PackageList[] = []
  private readonly customTemplate = "custom.template.ts";
  readonly customConfig = "config.schema.json";
  readonly cdfManifest = "cdf.manifest.json";
  packageId = ""
  ext = ".ts"

  /**
   * Retrieve a list of available packages from firestore
   * calling this sets the packages instance variable
   */
  public readonly getPackages = async () => {
    const pkgsRef = collection(db, col.pkgs);
    const pkgsQuery = query(pkgsRef, where("deleted", "==", false));
    const pkgs = await getDocs(pkgsQuery);
    const packgs = pkgs.docs.map(doc => {
      const { id } = doc;
      const pkg = doc.data() as Package;
      return { id, package: pkg }
    });

    // Check if the package files are available in storage
    const status = await Promise.all(packgs.map(async pkg => {
      const { id } = pkg;
      return await this.getFile(this.customTemplate, id)
        .then(() => ({ id, ready: true })).catch(() => {
          console.log(`Packages {} -- Package ${id} is not ready`);
          return ({ id, ready: false })
        })
    }));

    // Filter out packages that are not ready
    this.packages = packgs.filter(pkg => {
      const { id } = pkg;
      const { ready } = status.find(s => s.id === id);
      return ready;
    });
  }

  /**
   * Gets the language used in the package implementation
   * @param {string} pkgId Document id, space id and plan id
   * @return {ToolingLanguage} language used in the package implementation
   */
  public readonly getLanguage = (pkgId: string): ToolingLanguage => { 
    return this.packages.find(pkg => pkg.id === pkgId)?.package.language;
  }

  /**
   * Get package name from the list of packages saved in the instance variable
   * @param pkgId package id
   * @return package name
   */
  public readonly getPackageName = (pkgId: string) => {
    const pkg = this.packages.find(pkg => pkg.id === pkgId);
    if (pkg) return pkg.package.name;
    return "";
  }

  /**
   * Checks whether the selected plan has a specific package set to it
   * @param docId Orgs collection document id
   * @param spaceId Spaces collection document id
   * @param planId Plans collection document id
   * @returns Package id if set, empty string if not set
   */
  public readonly getPackageId = async (docId: string, spaceId: string, planId: string) => {
    const planRef = doc(db, col.orgs, docId, col.teams, docId, col.spaces, spaceId, col.plans, planId);
    const plan = await getDoc(planRef);
    if (plan.exists()) {
      const pkg = plan.data()?.package;
      if (pkg) return pkg;
    }
    console.log("Packages -- checkPlanPackage() -- No package set for this plan", planId);
    return "";
  }

  /**
   * Set the selected package id in the instance variable
   * @param pkg package id
   */
  setPackage = (pkg: string) => this.packageId = pkg;


  /**
   * Check if a package has been selected in the instance variable
   * @returns true if a package has been selected
   */
  IsPackegeSelected = () => this.packageId !== "";

  /**
   * Calls the getFile method to retrieve the files containing the
   * package configuration data
   */
  public readonly packageCfg = async () => {
    const data = await this.getFile(this.customTemplate)
    const source = await data.text();
    const custom = [Packages.setupTemplate(source, 0)];
    const content = await this.getFile(this.cdfManifest);
    return {
      custom,
      package: this.packageId,
      content: await content.text()
    }
  }

  /**
   * Update the plan with the selected package in the instance variable
   * if the value is set. To update with a specific package, pass the
   * package id as the fourth argument
   * @param docId orgs collections document id
   * @param spaceId spaces collections document id
   * @param planId plans collections document id
   */
  public readonly updatePlan = async (docId: string, spaceId: string, planId: string, pkg = this.packageId) => {
    const planRef = doc(db, col.orgs, docId, col.teams, docId, col.spaces, spaceId, col.plans, planId);
    this.packageId = pkg;
    const updateData = await this.packageCfg();
    await updateDoc(planRef, { ...updateData });
  }

  /**
   * Get the package vendor name that matches the package id value in the
   * plan document
   * @param packageId package id value
   * @returns package vendor name or null if not found
   */
  getPackageVendor = async (packageId: string) => {
    try {
      const plaRef = doc(db, col.pkgs, packageId);
      const snap = await getDoc(plaRef);
      return snap.data()?.vendor;
    } catch (e) {
      console.log("Packages.getPackageVendor() -- Error getting vendor ", e);
      return null;
    }

  }

  /**
   * Gets a file from firebase storage as a blob | Expects to be called
   * after calling the setPackage method, otherwise can be called by
   * passing the package id as the second argument
   */
  public readonly getFile = async (fileName: string, id = this.packageId) => {
    if (id) console.error("Packages.getFile() packageId not set");
    const fileRef = ref(storage, `${col.pkgs}/${id}/${fileName}`)
    const blob = await getBlob(fileRef)

    return blob
  }

  /**
   * Set up the template file for usage
   * @param source template file content
   * @param index custom module index
   * @returns template with data
   */
  static readonly setupTemplate = (source: string, index: number ) => {
    const data = { moduleName: `custom${index}`};
    Object.entries(data).forEach(([key, value]) => {
      source = source.replace(new RegExp(`{{${key}}}`, "g"), value);
    });
    return source;
  };

  public readonly getExt = () => this.ext;
  
}


// Instanciating the class early to share data across the app
// Using the same instance
export const packages = new Packages();

export default Packages
