import { FC, Fragment } from "react";
import { ModalBody } from "@chakra-ui/react";
import {
  CustomInput,
  CustomSelect,
  CustomModalFooter
} from "../components";
import { pkgs } from "functions";
import { usePlans } from "./useplans";
import { useState, useEffect } from "react";
import { AddPlanProps } from "../types";
import { useAuth } from "context/AuthContext";

/**
 * Add plan popup
 * @param props Add plan popup props
 * @returns JSX.Element
 */
export const AddPlan: FC<AddPlanProps> = props => {
  const { onClose, spaceId } = props;
  const { addPlan, plans, spaceRef } = usePlans();
  const [disabled, setDisabled] = useState(true);
  spaceRef.current = props.spaceId;

  const [loading, setLoading] = useState(false);

  const [packages, setPackages] = useState([]);
  const { docId } = useAuth()

  const [plan, setPlan] = useState({
    name: "",
    copyFrom: "",
    package: "",
  });

  const [errorState, setErrorState] = useState({
    name: false,
    copyFrom: false,
    package: false,
  });

  const [packageDetails, setPackageDetails] = useState({
    library: "",
    runtime: "",
    vendor: "",
    language: "",
  });

  /**
   * Add plan to firestore then close the popup window
   */
  const handleAddPlan = async () => {
    setLoading(true);
    const data = {
      name: plan.name,
      package: plan.package,
    }
    if (plan.copyFrom) {
      const id = plan.copyFrom;
      const copyPlan = plans.find(_plan => _plan.id === id);
      Object.assign(data, { content: copyPlan.content });
    }
    await addPlan(spaceId, data)
      .then(() => setLoading(false));
    onClose();
  }

  /**
   * Onchange handler for input fields
   * @param e Input event
   */
  const onChange = (e: any) => {
    const { name, value } = e.target;
    setPlan({ ...plan, [name]: value });
    if (name === "package") {
      pkgs.setPackage(value);
    }
  };

  /**
   * Update error state for each field when data is changed
   * @param e Input event
   */
  const validateField = (e: any) => {
    const { name, value } = e.target;
    if (value.trim() === "") {
      setErrorState({ ...errorState, [name]: true });
    } else {
      setErrorState({ ...errorState, [name]: false });
    }
  };

  /**
   * Disable button if any field is empty or has error
   */
  useEffect(() => {
    const disableBtn = Object.values(errorState).some((val) => val);
    const empty = plan.name === "" || plan.package === "";
    setDisabled(disableBtn || empty || loading);
  }, [errorState, plan, loading]);


  // Filter packages if copy from is used
  useEffect(() => {
    const planID = plan.copyFrom;

    if (!planID || planID === "") {
      setPackages(pkgs.packages)
      return;
    }
    (async () => {
      const packageID = await pkgs.getPackageId(docId, spaceId, planID)
      const vendor = await pkgs.getPackageVendor(packageID)
      const _packages = pkgs.packages.filter(pkg => pkg.package.vendor === vendor);
      setPackages(_packages)
    })();
  }, [plan.copyFrom, docId, spaceId])

  // Display package metadata on selection
  useEffect(() => {
    if (plan.package) {
      const _package = pkgs.packages.find(pkg => pkg.id === plan.package);
      if (_package) {
        const data: any = _package.package || {};
        setPackageDetails({
          library: data.library || " ",
          runtime: data.runtime || " ",
          vendor: data.vendor || " ",
          language: data.language || " ",
        });
      }
    } else {
      setPackageDetails(null);
    }
  }, [plan.package])


  return (
    <Fragment>
      <ModalBody>
        <CustomInput
          label="Name" isRequired name="name"
          placeholder="Enter plan name"
          onChange={(e) => {
            onChange(e);
            validateField(e);
          }}
          onBlur={validateField}
          isInvalid={errorState.name}
          error="Name is required"
        />
        {plans.length > 0 &&
          <CustomSelect
            label="Copy From"
            placeholder="Select option"
            data={plans} name="copyFrom"
            onChange={onChange}
          />}
        <CustomSelect
          isRequired
          label="Packages"
          placeholder="Select option"
          name="package"
          data={packages.map(pkg => {
            return { name: pkg.package.name, id: pkg.id }
          })}
          onChange={(e) => {
            onChange(e);
            validateField(e);
          }}
          onFocus={validateField}
          isInvalid={errorState.package}
          error="Package is required"
        />
        {
          plan.package && Object.entries(packageDetails || {}).map(([key, value]) =>
            <Fragment>
              <CustomInput
                label={`Package ${key}`}
                value={value as string}
                error=""
                disabled
              />
            </Fragment>)
        }
      </ModalBody>
      <CustomModalFooter
        onAccept={handleAddPlan}
        onCancel={onClose}
        cancelLabel="Close"
        acceptLabel={loading ? "please wait..." : "Add Plan"}
        disabled={disabled}
      />
    </Fragment>
  )
}
