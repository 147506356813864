import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel
} from "@chakra-ui/react";
import { FC } from "react";
import { inputContainerProps } from "../types";

export const InputContainer: FC<inputContainerProps> = props => {
  const { children, label, textColor, error, ...rest } = props
  return (
    <Flex
      direction="row"
      justifyContent="center"
      alignItems="center"
      background="transparent"
      borderRadius="15px"
    >
      <Box
        w={{ base: "100%", md: "420px" }}
        maxW="100%"
        ml="16px"
        mr="16px"
        mb="24px"
      >
        <FormControl {...rest} >
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            {label}
          </FormLabel>
          {children}
          <FormErrorMessage>
            {error}
          </FormErrorMessage>
        </FormControl>
      </Box>
    </Flex>
  );
};
